import React, { useState, useEffect } from 'react';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        const rememberMe = document.getElementById("remember-me").checked;

        try {
            const response = await fetch('/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, rememberMe }),
                credentials: 'include', // Include cookies in requests
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            // Redirect to the home page after successful login
            window.location.href = "/";
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        const rememberedEmail = localStorage.getItem('remembered_email');
        if (rememberedEmail) {
            setEmail(rememberedEmail);
        }
    }, []);

    return (
        <div className="login col col-12">
            <div className="form-signin position-absolute top-50 start-50 translate-middle">
                <form onSubmit={handleLogin}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3><strong className="green-text">Login</strong></h3>
                        </div>
                    </div>
                    {error && (
                        <div id="error-message" className="alert alert-danger">
                            {error}
                        </div>
                    )}
                    <div className="row">
                        <div className="col-12 form-floating">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 form-floating">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="password">Password</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <a href="/reset-password">Forgot Password?</a>
                            <label>
                                <input type="checkbox" id="remember-me" /> Remember me
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary w-100">Sign In</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
